import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppRouterService } from '../services/app-router.service';
import { AppStateService } from '../services/app-state.service';
import { SnackbarService } from '../services/snackbar.service';
import { LoggerService } from '../services/sys/logger.service';
import { environment } from 'src/environments/environment';
import { Webservices } from '../constants/routes/webservices.constant';
import { targetApi } from '../utils/url.utils';
import { Routes } from '../constants/routes/routes.constant';
import { getProp } from '../utils/typing.utils';

@Injectable()
export class ClearBearerInterceptor implements HttpInterceptor {
  private readonly whitelist: string[] = [
    environment.apiProtocol + environment.apiHost + Webservices.token,
  ];

  /**
   * Bearer clear management
   */
  public constructor(
    private readonly _appState: AppStateService,
    private readonly _appRouter: AppRouterService,
    private readonly _logger: LoggerService,
    private readonly _snackbar: SnackbarService
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: unknown): ObservableInput<HttpEvent<unknown>> => {
        const status: number | undefined = getProp(error, 'status');
        const message: string | undefined = getProp(error, 'message');

        if (
          status === 401 &&
          targetApi(request.url) &&
          !this.whitelist.includes(request.url)
        ) {
          // We clear the state (we could refresh the token)
          this._appState.logout();
          this._logger.warning(
            `Http error 401 - ${message}, clearing bearer and retrying`,
            request.url,
            getProp(error, 'error')
          );

          return next
            .handle(
              request.clone({
                setHeaders: {
                  Authorization: '',
                },
              })
            )
            .pipe(
              catchError((error: unknown): Observable<never> => {
                // If we still get an error we clear the session and redirect to the homepage
                const status: number | undefined = getProp(error, 'status');
                if (status === 401) {
                  this._appState.logout();
                  this._appRouter.navigate(Routes.home);
                  this._snackbar.warning('Votre session a expiré.');
                }

                return throwError(() => error);
              })
            );
        } else {
          return throwError(() => error);
        }
      })
    );
  }
}
