import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppStateService } from '../services/app-state.service';
import { MaintenanceService } from '../services/maintenance.service';
import { SsrResponseService } from '../services/ssr/ssr-response.service';
import { LoggerService } from '../services/sys/logger.service';
import { getProp } from '../utils/typing.utils';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private _checking: boolean = false;

  public constructor(
    private readonly _logger: LoggerService,
    private readonly _ssrResponse: SsrResponseService,
    private readonly _appState: AppStateService,
    private readonly _maintenance: MaintenanceService
  ) {}

  /**
   * Http errors handle
   */
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: unknown): Observable<never> => {
        const status: number | undefined = getProp(error, 'status');
        const message: string | undefined = getProp(error, 'message');

        this._logger.error(
          `Http error ${status} - ${message}`,
          request.url,
          getProp(error, 'error')
        );

        if (!status || status === 500) {
          // We don't want to re-check the maintenance status for the maintenance check request
          if (!this._checking && request.url !== this._maintenance.checkUrl) {
            this._checking = true;
            this._maintenance.check().subscribe((status: boolean) => {
              this._appState.maintenance = !status;
              this._checking = false;
            });
          }
        }

        this._ssrResponse.setStatus(
          status || 500,
          getProp(error, 'statusText')
        );
        return throwError(() => error);
      })
    );
  }
}
