import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Image } from 'src/app/shared/models/dto/image';
import { AbstractComponent } from '../../abstract.component';
import { ResponsiveService } from 'src/app/shared/services/html/responsive.service';
import { debounceTime, takeUntil } from 'rxjs';

interface ImageProps {
  src: string;
  alt?: string;
  title?: string;
}

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent
  extends AbstractComponent
  implements OnInit, OnChanges
{
  @Input({ required: true }) public image?:
    | (Image | string)[]
    | (Image | string);

  @Input() public display?: 'fit' | 'cover';
  @Input() public alt?: string;
  @Input() public title?: string;
  @Input() public draggable: boolean = true;
  @Input() public usePath: boolean = false; // obviously only works if this.image is an Image

  @Input() public guessHeight: boolean = true;

  @Input() public maxWidth?: string;
  @Input() public maxHeight?: string;

  public loaded: boolean = false;

  public props?: ImageProps;

  public constructor(private readonly _responsive: ResponsiveService) {
    super();
  }

  public ngOnInit(): void {
    this._responsive.resize$
      .pipe(debounceTime(300), takeUntil(this._destroyed$))
      .subscribe((width: number | undefined) => {
        this.setImage();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['image']) {
      this.setImage();
    }
  }

  private setImage(): void {
    let props: ImageProps;
    if (typeof this.image === 'string') {
      props = this.stringProps(this.image);
    } else if (Array.isArray(this.image)) {
      if (this.image?.length) {
        const images: Image[] = this.image.filter(
          (image: Image | string) => typeof image !== 'string'
        );
        if (images.length) {
          // We try to find the best image for the current screen
          const clientWidth: number | undefined = this._responsive.clientWidth;
          props = this.imageProps(
            images.reduce((prev: Image, current: Image) => {
              if (!prev.width) {
                return current;
              } else if (!current.width) {
                return prev;
              } else if (clientWidth) {
                if (prev.width > clientWidth && current.width > clientWidth) {
                  return prev.width < current.width ? prev : current;
                } else if (
                  prev.width < clientWidth &&
                  current.width < clientWidth
                ) {
                  return prev.width > current.width ? prev : current;
                } else {
                  return current.width > prev.width ? current : prev;
                }
              } else {
                return current.width < prev.width ? current : prev;
              }
            }, images[0])
          );
        } else {
          props = this.stringProps(this.image[0] as string);
        }
      } else {
        props = this.stringProps();
      }
    } else {
      props = this.imageProps(this.image);
    }

    // We check the difs to avoid flicker in case the ref change but the image stay the same
    if (
      props.src !== this.props?.src ||
      props.alt !== this.props?.alt ||
      props.title !== this.props?.title
    ) {
      this.props = props;

      // We reset the loading state if the source change
      if (props.src !== this.props?.src) {
        this.loaded = false;
      }
    }
  }

  private imageProps(image?: Image): ImageProps {
    return {
      src: (this.usePath ? image?.path : image?.cachedPath) ?? '',
      alt: this.alt ?? image?.alt,
      title: this.title ?? image?.title,
    };
  }

  private stringProps(image?: string): ImageProps {
    return {
      src: image ?? '',
      alt: this.alt,
      title: this.title,
    };
  }
}
