import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  asyncScheduler,
  combineLatest,
  fromEvent,
  map,
  startWith,
  throttleTime,
} from 'rxjs';

export enum Breakpoints {
  none = 0,
  xSmall = 720,
  small = 1024,
  medium = 1280,
  large = 1440,
  xLarge = 1920,
}

export interface CSSVariables {
  pushHeight?: string;
  navHeight?: string;
  headerHeight?: string;
  panelHeight?: string;
  accountHeight?: string;
  debugHeight?: string;
  asideWidth?: string;
  layoutWidth?: string;
  layoutPadding?: string;
  layoutGap?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private _rootElement?: HTMLElement;

  private readonly _refresh$: Subject<void> = new Subject<void>();
  private readonly _breakpoint$: BehaviorSubject<Breakpoints>;
  private readonly _variables$: BehaviorSubject<CSSVariables>;

  public constructor(@Inject(DOCUMENT) private readonly _document: Document) {
    this._refresh$ = new Subject<void>();
    this._breakpoint$ = new BehaviorSubject<Breakpoints>(Breakpoints.none);
    this._variables$ = new BehaviorSubject<CSSVariables>({});
  }

  public get resize$(): Observable<number | undefined> {
    return fromEvent(
      this._document.defaultView ?? new HTMLElement(),
      'resize'
    ).pipe(
      throttleTime(50, asyncScheduler, { leading: true, trailing: true }),
      map(() => this._document.body.clientWidth)
    );
  }

  public bootstrap(rootElement?: HTMLElement): void {
    this._rootElement = rootElement;

    combineLatest([
      this.resize$.pipe(startWith(undefined)),
      this._refresh$.pipe(startWith(undefined)),
    ]).subscribe(([e, v]: [number | undefined, void]) => {
      this.updateBreakpoint();
      this.updateCssVariables();
    });
  }

  public refresh(): void {
    this._refresh$.next();
  }

  public get breakpoint(): Breakpoints {
    return this._breakpoint$.value;
  }

  public get breakpoint$(): Observable<Breakpoints> {
    return this._breakpoint$.asObservable();
  }

  public get variables(): CSSVariables {
    return this._variables$.value;
  }

  public get variables$(): Observable<CSSVariables> {
    return this._variables$.asObservable();
  }

  public get tablet(): boolean {
    return !!(
      this._breakpoint$.value &&
      this._breakpoint$.value <= Breakpoints.small &&
      this._breakpoint$.value >= Breakpoints.xSmall
    );
  }

  public get mobile(): boolean {
    return !!(
      this._breakpoint$.value && this._breakpoint$.value <= Breakpoints.xSmall
    );
  }

  public get clientWidth(): number | undefined {
    return this._document.body.clientWidth;
  }

  private updateBreakpoint(): void {
    const width: number | undefined = this.clientWidth;
    let breakpoint: Breakpoints = Breakpoints.none;
    if (width) {
      switch (true) {
        case width <= Breakpoints.xSmall:
          breakpoint = Breakpoints.xSmall;
          break;
        case width <= Breakpoints.small:
          breakpoint = Breakpoints.small;
          break;
        case width <= Breakpoints.medium:
          breakpoint = Breakpoints.medium;
          break;
        case width <= Breakpoints.large:
          breakpoint = Breakpoints.large;
          break;
        case width <= Breakpoints.xLarge:
          breakpoint = Breakpoints.xLarge;
          break;
      }
    }

    if (breakpoint !== this._breakpoint$.value) {
      this._breakpoint$.next(breakpoint);
    }
  }

  private updateCssVariables(): void {
    if (this._document.defaultView) {
      const styles: CSSStyleDeclaration =
        this._document.defaultView.getComputedStyle(
          this._rootElement ?? this._document.documentElement
        );

      const variables: CSSVariables = {
        navHeight: styles.getPropertyValue('--nav-height'),
        pushHeight: styles.getPropertyValue('--push-height'),
        headerHeight: styles.getPropertyValue('--header-height'),
        panelHeight: styles.getPropertyValue('--panel-height'),
        accountHeight: styles.getPropertyValue('--account-height'),
        debugHeight: styles.getPropertyValue('--debug-height'),
        asideWidth: styles.getPropertyValue('--aside-width'),
        layoutWidth: styles.getPropertyValue('--layout-width'),
        layoutPadding: styles.getPropertyValue('--layout-padding'),
        layoutGap: styles.getPropertyValue('--layout-gap'),
      };

      if (!isEqual(variables, this._variables$.value)) {
        this._variables$.next(variables);
      }
    }
  }
}
